import { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import LocationMarker from "./LocationMarker";
import NewMarker from "./NewMarker";
import BottomMenu from "./BottomMenu";
import { MapStyled } from "../style/componentStyle";
import MarkerCluster from "./MarkerCluster";
import MarkerPopup from "./modal/MarkerDetail/MarkerPopup";
import { useDisclosure } from "@chakra-ui/react";
import { addMonths, format } from "date-fns";
import { Search } from "./Search";

import { useMarkerStore } from "../store/useMarkerStore";
import { useDateStore } from "../store/useDateStore";
import { isInKorea } from "@/utils/mapUtil";
import { useUserStore } from "@/store/useUserStore";
import { LatLngExpression } from "leaflet";
import { useGetLocationList } from "@/api/map";
import { useRefetchStore } from "../store/useRefetchStore";

function Map() {
  // 데이터 패칭을 위해 필요한 최소 줌 레벨 (예: 12)
  const MIN_DATA_ZOOM = 10;

  const userInfo = useUserStore((state) => state.getUser());
  const mapRef = useRef(null);
  const [saveMode, setSaveMode] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const marker = useMarkerStore((state) => state.marker);
  const date = useDateStore((state) => state.getDate());
  const dateType = useDateStore((state) => state.getDateType());

  const [currentPosition, setCurrentPosition] = useState({
    lat: 37.566,
    lng: 126.98,
  });

  const handleCurrentLocation = () => {
    const map = mapRef.current;
    setSaveMode(false);
    if (map != null) {
      map.locate().on("locationfound", function (e) {
        map.flyTo(e.latlng, 16);
      });
    }
  };

  const onSaveMode = () => {
    setSaveMode(!saveMode);
  }; //위치 저장모드로 변경

  const [queryBounds, setQueryBounds] = useState(null);
  const fetchBoundsLocation = async (bounds, startDate) => {
    if (!userInfo || !mapRef.current) return;

    // 현재 줌 레벨 체크: MIN_DATA_ZOOM 미만이면 marker 데이터를 요청하지 않습니다.
    if (mapRef.current.getZoom() < MIN_DATA_ZOOM) {
      setQueryBounds(null); // 혹은 marker들을 제거하기 위해 null 할당
      return;
    }

    const endDate =
      dateType === "year"
        ? `${startDate}-12-31 23:59:59`
        : format(addMonths(new Date(startDate), 1), "yyyy-MM");
    const locationData = {
      northEast_lat: bounds._northEast.lat,
      northEast_lng: bounds._northEast.lng,
      southWest_lat: bounds._southWest.lat,
      southWest_lng: bounds._southWest.lat,
    };
    setQueryBounds({
      ...locationData,
      startDate:
        dateType === "year"
          ? `${startDate}-01-01 00:00:00`
          : `${startDate}-01 00:00:00`,
      endDate: endDate,
      userId: userInfo?.id,
    });
  }; //범위 내 마커리스트 가져오기

  const [render, setRender] = useState(0);

  useEffect(() => {
    if (!userInfo) return;
    const map = mapRef.current;
    if (map) {
      const handleMoveEnd = () => {
        const center = map.getCenter();
        setCurrentPosition({ lat: center.lat, lng: center.lng });
        const bounds = map.getBounds();
        fetchBoundsLocation(bounds, date);
      };
      map.on("moveend", handleMoveEnd); //지도 이동 후 이벤트
      return () => {
        map.off("moveend", handleMoveEnd);
      };
    } else {
      setRender((pre) => pre + 1);
    }
  }, [mapRef, render, date, userInfo]);

  const { data: locationList, refetch: locationListRefetch } =
    useGetLocationList(queryBounds);

  const setLocationListRefetch = useRefetchStore(
    (state) => state.setLocationListRefetch
  );

  useEffect(() => {
    setLocationListRefetch(locationListRefetch);
  }, [locationListRefetch]);

  useEffect(() => {
    const map = mapRef.current;
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (map) {
            const { latitude, longitude } = position.coords;
            map.flyTo([latitude, longitude], 16);
          }
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              alert("위치 정보 접근을 허용해주세요.");
              break;
            case error.POSITION_UNAVAILABLE:
              alert("위치 정보를 가져올 수 없습니다.");
              break;
            case error.TIMEOUT:
              alert("요청 시간이 초과되었습니다.");
              break;
          }
        },
        options
      );
    } else {
      alert("이 브라우저에서는 위치 서비스를 지원하지 않습니다.");
    }
  }, [mapRef]);

  const createThumbnailIcon = (thumbnailUrl) => {
    return L.divIcon({
      html: `
       <img src=${thumbnailUrl} alt="thumbnail" />
      `,
      className: "thumbnail-marker",
      iconSize: [40, 40],
      iconAnchor: [25, 25],
    });
  };

  const defaultCenter: LatLngExpression = [37.566, 126.98];

  // 지도 이동 감지를 위한 useEffect
  useEffect(() => {
    if (!userInfo || !mapRef.current) return;

    const map = mapRef.current;
    const handleMoveEnd = () => {
      const center = map.getCenter();
      setCurrentPosition({ lat: center.lat, lng: center.lng });
      const bounds = map.getBounds();
      fetchBoundsLocation(bounds, date);
    };

    map.on("moveend", handleMoveEnd);
    return () => {
      map.off("moveend", handleMoveEnd);
    };
  }, [userInfo, mapRef.current]);

  // date 변경 감지를 위한 useEffect
  useEffect(() => {
    if (!userInfo || !mapRef.current) return;

    const map = mapRef.current;
    const bounds = map.getBounds();
    fetchBoundsLocation(bounds, date);
  }, [date, userInfo]);

  useEffect(() => {
    // 모바일 기기인 경우에만 설정 적용
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      L.Browser.touch = true;
      L.Browser.retina = true;
      L.Browser.mobile = true;
    }
  }, []);

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      // 맵의 이동 가능한 영역을 제한
      const southWest = L.latLng(-90, -180);
      const northEast = L.latLng(90, 180);
      const bounds = L.latLngBounds(southWest, northEast);

      map.setMinZoom(8); // minZoom 직접 설정
      map.setMaxBounds(bounds);
      map.options.minZoom = 8; // 옵션에도 minZoom 설정
    }
  }, [mapRef.current]);

  return (
    <>
      <MapStyled>
        <MapContainer
          ref={mapRef}
          center={defaultCenter}
          zoom={13}
          maxZoom={17}
          style={{ height: "100%", width: "100%" }}
          whenCreated
        >
          <BottomMenu
            handleCurrentLocation={handleCurrentLocation}
            setSaveMode={setSaveMode}
            onSaveMode={onSaveMode}
            saveMode={saveMode}
          />
          {saveMode && (
            <>
              <div className="save-pointer-box">
                <div className="pointer"></div>
                <p className="info-txt">
                  맵을 움직여 정확한 위치를 선택해주세요
                </p>
              </div>
              <NewMarker
                setSaveMode={setSaveMode}
                setRender={setRender}
                onSaveMode={onSaveMode}
              />
            </>
          )}
          {locationList && MIN_DATA_ZOOM < mapRef.current.getZoom() && (
            <>
              <MarkerCluster
                onOpen={onOpen}
                markers={locationList.list}
                createIcon={createThumbnailIcon}
              />
            </>
          )}

          {marker && (
            <MarkerPopup data={marker} isOpen={isOpen} onClose={onClose} />
          )}

          {/* 중심 좌표 기준으로 타일 선택 */}
          {isInKorea(currentPosition.lat, currentPosition.lng) ? (
            <TileLayer
              attribution='&copy; <a href="https://www.vworld.kr">vworld</a>'
              url={`https://api.vworld.kr/req/wmts/1.0.0/${
                import.meta.env.VITE_VWORLD_API_KEY
              }/Base/{z}/{y}/{x}.png`}
              tileSize={256}
              zoomOffset={0}
              retina={"@2x"}
            />
          ) : (
            <TileLayer
              attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a>'
              url={`https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/256/{z}/{x}/{y}@2x?access_token=${
                import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
              }`}
              zoomOffset={-1}
            />
          )}
          {/* <Search /> */}
          <LocationMarker />
          {/* Additional map layers or components can be added here */}
        </MapContainer>
      </MapStyled>
    </>
  );
}

export default Map;
